.judge-grid {
	width: 100%;
}

.candidate {
	display: grid;
	grid-template-columns: 1fr 5fr minmax(200px,10fr) 3fr 7.5fr;
	grid-gap: 0.5rem;
	margin-bottom: 0.5rem;
	background-color: #f3f3f3;
	padding: 0.5rem;
	border: var(--border-width) solid transparent;
	border-radius: var(--border-radius);
	> div {
		grid-column: span 1;
	}
	.select {
		place-self: center;
	}
	.icons {
		place-self: center start;
	}
	.select, .icons {
		white-space: nowrap;
		@fontSize: 3em;
		.mat-icon {
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--border-radius);
			font-size: @fontSize;
			&::before {
				background-color: var(--muted-color);
			}
			&.check {
				&::before {
					background-color: var(--success-text-color);
				}
			}
			&.cancel {
				&::before {
					background-color: var(--error-button-bgcolor);
				}
			}
			&.question_mark {
				&::before {
					background-color: var(--warning-button-bgcolor);
				}
			}
			&.event_available {
				&::before {
					background-color: var(--success-text-color);
				}
			}
			&.event_busy {
				&::before {
					background-color: var(--error-button-bgcolor);
				}
			}
		}
		.wr-status {
			position: relative;
			display: inline-block;
			width: @fontSize;
			height: @fontSize;
			.status {
				position: absolute;
				top: 0;
				left: 0;
				width: @fontSize;
				height: @fontSize;
				background-size: cover;
				&.nicht-verfuegbar {
					background-image: url(../../../icons/konkurrierende_einsaetze.svg);
				}
				&.absagen {
					background-image: url(../../../icons/konkurrierende_absagen.svg);
				}
			}
			.mat-icon {
				&.faded {
					&:before {
						opacity: 0.15;
					}
				}
			}
		}
	}
	.judge {
		place-self: center start;
		max-width: 100%;
		width: 100%;
		.name, .club {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
		}
		.name {
			padding-bottom: 0.5rem;
		}
	}
	.license {
		place-self: start center;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0.5rem;
		div {
			place-self: center;
			width: 100%;
			text-align: center;
			&.value {
				font-weight: bold;
			}
		}
	}
	.stats {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 0.5rem;
		div {
			text-align: center;
			&.value {
				font-weight: bold;
			}
		}
	}
}

.einsatzplaner-turniere-table {
	td {
		&.datum {
			width: 30%;
		}
		&.uhrzeit {
			width: 20%;
		}
		&.turnier {
			width: 25%;
		}
		&.bemerkungen {
			width: 25%;
		}
	}
}
