@import 'normalizecss/normalize.css';
@import 'tinymce/skins/ui/oxide/skin.css';
@import 'dropzone/dist/dropzone.css';
@import 'chosen-js/chosen.css';

@fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
@fontSize: 0.9rem;

@monoSpaceFontFamily: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

@textColor: #2d2d2d;
@borderColor: #d0d0d0;
@linkColor: #0D6986;

@primaryButtonColor: @linkColor;
@primaryButtonTextColor: white;
@secondaryButtonColor: lightgrey;
@secondaryButtonTextColor: @textColor;

@errorColor: #e00000;
@warningColor: #ffa500;
@successColor: #d1e7dd;
@infoColor: #a9d7fc;

@tableBorderColor: @borderColor;
@tableStripeColorEven: lighten(@tableBorderColor, 15%);
@tableStripeColorOdd: white;

// css variables
:root {
	--link-color: @linkColor;
	--link-hover-color: lighten(@linkColor, 5%);

	--primary-color: @linkColor;
	--primary-highlight-color: lighten(@linkColor, 5%);
	--primary-disabled-color: lighten(@linkColor, 10%);
	--primary-text-color: @primaryButtonTextColor;

	--secondary-color: @secondaryButtonColor;
	--secondary-highlight-color: lighten(@secondaryButtonColor, 5%);
	--secondary-disabled-color: lighten(@secondaryButtonColor, 10%);
	--secondary-text-color: @secondaryButtonTextColor;

	--error-button-bgcolor: @errorColor;
	--error-button-highlight-bgcolor: lighten(@errorColor, 5%);
	--error-button-disabled-bgcolor: lighten(@errorColor, 30%);
	--error-button-text-color: white;
	--error-bgcolor: lighten(@errorColor, 40%);
	--error-text-color: darken(@errorColor, 20%);

	--warning-button-bgcolor: @warningColor;
	--warning-button-highlight-bgcolor: lighten(@warningColor, 10%);
	--warning-button-disabled-bgcolor: lighten(@warningColor, 30%);
	--warning-button-text-color: white;
	--warning-bgcolor: lighten(@warningColor, 30%);
	--warning-text-color: darken(@warningColor, 30%);

	--success-button-bgcolor: @successColor;
	--success-button-highlight-bgcolor: lighten(@successColor, 10%);
	--success-button-disabled-bgcolor: lighten(@successColor, 30%);
	--success-button-text-color: darken(@successColor, 50%);;
	--success-bgcolor: @successColor;
	--success-text-color: darken(@successColor, 50%);

	--info-bgcolor: @infoColor;
	--info-text-color: darken(@infoColor, 50%);

	--text-color: @textColor;
	--font-family: @fontFamily;
	--font-size-normal: @fontSize;

	--border-color: @borderColor;
	--border-width: 0.05rem;
	--border-radius: 0.2rem;

	--muted-color: darkgray;
}
// end css-variables

*, *:before, *:after {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
}

:focus {
	outline: @focusOutline;
}


body {
	color: var(--text-color);
	font-family: var(--font-family);
	font-size: var(--font-size-normal);
	margin: 0;
	padding: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	display: block;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: bold;
	line-height: 1.2;
	margin-top: 0;
	word-break: break-word;
	outline: 0 !important;
}

h1, .h1 {
	margin-bottom: 1rem;
	font-size: 1.5rem;
}

h2, .h2 {
	margin-bottom: 0.9rem;
	font-size: 1.4rem;
}

h3, .h3 {
	margin-bottom: 0.8rem;
	font-size: 1.3rem;
}

h4, .h4 {
	margin-bottom: 0.7rem;
	font-size: 1.2rem;
}

h5, .h5 {
	margin-bottom: 0.6rem;
	font-size: 1.1rem;
}

h6, .h6 {
	margin-bottom: 0.4rem;
	font-size: 1rem;
}

a {
	color: var(--link-color);
	text-decoration: none;
	&:link, &:visited, &:active {
		color: var(--link-color);
	}
	&:hover {
		color: var(--link-hover-color);
		transition: color ease-in-out .15s;
		text-decoration: underline;
	}
}

p {
	margin-bottom: 1rem;
}

ol, ul {
	padding-left: 2rem;
	margin-bottom: 1rem;
	li {
		margin-bottom: 0.3rem;
		ul {
			margin-top: 0.3rem;
			margin-bottom: 0;
		}
	}
}

code, kbd, pre, samp {
	font-family: @monoSpaceFontFamily;
	font-size: var(--font-size-normal);
}

button {
	line-height: normal;
}

.text-right {
	text-align: right !important;
}

.text-justify {
	text-align: justify !important;
}

.text-center {
	text-align: center !important;
}

.text-muted {
	color: var(--muted-color);
}

@import url(includes/modern/layout);

@import url(includes/modern/components/mandanteninfo);

@import url(includes/modern/components/userinfo);

@import url(includes/modern/components/topnav);

@import url(includes/modern/components/sidenav);

@import url(includes/common/playground/card);
@import url(includes/modern/playground/card);

@import url(includes/common/components/dashboard);
@import url(includes/modern/components/dashboard);

@import url(includes/common/playground/badge);

@import url(includes/modern/playground/modal);

@import url(includes/common/playground/nav);
@import url(includes/modern/playground/nav);

@import url(includes/common/playground/tabs);
@import url(includes/modern/playground/tabs);

@import url(includes/modern/forms);

@import url(includes/modern/tables);

.flags {
	font-family: @monoSpaceFontFamily;
}

@import url(includes/modern/components/pager);

@import url(includes/modern/components/freigabe_kalender);

@import url(includes/modern/components/alert);

@import url(includes/modern/components/material-icons);

@import url(includes/modern/components/veranstaltungs-liste);

@import url(includes/modern/components/progress);

@import url(includes/modern/components/claim);

@import url(includes/modern/components/veranstaltungs-editor);

@import url(includes/modern/components/einsatz-planer);

@import url(includes/modern/components/list-actions);

@import url(includes/modern/components/vereins-liste);

@import url(includes/modern/components/benutzer-liste);

@import url(includes/modern/components/turnierstaetten-liste);

@import url(includes/modern/components/startgruppen-liste);

@import url(includes/modern/components/wrdetail-modal);

@import url(includes/modern/components/toast);

@import url(includes/modern/components/lizenzen-liste);

@import url(includes/modern/components/startklassen-liste);

@import url(includes/modern/components/wr-competition-table);

@import url(includes/modern/components/turnierkalender);

@import url(includes/modern/components/wr-info);

@import url(includes/modern/utilities);

@import url(includes/modern/components/emailer);

@import url(includes/modern/components/image-resources);

@import url(includes/modern/components/main-menu-button);

@import url(includes/modern/buttons);

@import url(includes/modern/input);
