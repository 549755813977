@import '../variables';

.mixin-input-message() {
	font-size: 0.8rem;
	line-height: 1.4rem;
}

.mixin-input-default() {
	padding: 0.5rem;
	border: 0.05rem solid var(--border-color);
	border-radius: var(--border-radius);
	background-color: white;
	width: 100%;
	&[readonly] {
		background-color: #fafafa;
	}
	&[disabled] {
		background-color: #f0f0f0;
		cursor: not-allowed;
	}
}

.mixin-button(@bgColor, @bgHighlightColor, @bgDisabledColor, @textColor, @borderRadius) {
	padding: 0.5rem;
	border-radius: @borderRadius;
	border: var(--border-width) solid @bgColor;
	background-color: @bgColor;
	color: @textColor !important;	// needed for a
	font-weight: bolder;
	white-space: nowrap;
	margin: 0 @inputHorizontalMargin 0 @inputHorizontalMargin;

	&:not(:disabled):hover, &:not(.disabled):hover {
		background-color: @bgHighlightColor;
		border-color: @bgHighlightColor;
		transition: background-color ease-in-out .15s;
		text-decoration: none !important; // needed for a
	}

	.mat-icon {
		margin-right: 0.5em;
		&:before {
			background-color: @textColor;
		}
	}

	&:disabled, &.disabled {
		pointer-events: none;
		background-color: @bgDisabledColor;
		border-color: @bgDisabledColor;
	}

	.mixin-button-small();

	&.btn-icon {
		.mat-icon {
			margin-right: 0;
		}
	}
}

.mixin-button-small() {
	&.btn-small {
		padding: 0.25rem;
		font-size: 0.75em;
	}
}

.mixin-button-primary() {
	.mixin-button(var(--primary-color), var(--primary-highlight-color), var(--primary-disabled-color), var(--primary-text-color), var(--border-radius));
}

.mixin-button-secondary() {
	.mixin-button(var(--secondary-color), var(--secondary-highlight-color), var(--secondary-disabled-color), var(--secondary-text-color), var(--border-radius));
}

.mixin-button-error() {
	.mixin-button(var(--error-button-bgcolor), var(--error-button-highlight-bgcolor), var(--error-button-disabled-bgcolor), var(--error-button-text-color), var(--border-radius));
}

.mixin-button-warning() {
	.mixin-button(var(--warning-button-bgcolor), var(--warning-button-highlight-bgcolor), var(--warning-button-disabled-bgcolor), var(--warning-button-text-color), var(--border-radius));
}

.mixin-button-success() {
	.mixin-button(var(--success-button-bgcolor), var(--success-button-highlight-bgcolor), var(--success-button-disabled-bgcolor), var(--success-button-text-color), var(--border-radius));
}

.mixin-checkbox-default() {
	input[type=checkbox] {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: white;
		margin: 0 0.3rem 0 0.3rem;
		font: inherit;
		color: currentColor;
		width: 0.9rem;
		height: 0.9rem;
		border: var(--border-width) solid currentColor;
		border-radius: 0.1rem;
		vertical-align: text-top;
		&:checked {
			box-shadow: inset 0 0 0 0.15rem white;
			background-color: var(--primary-color);
		}
		&:indeterminate {
			box-shadow: inset 0 0 0 0.15rem white;
			background-color: var(--border-color);
		}
		&:disabled {
			color: var(--muted-color);
			cursor: not-allowed;
			// this does not work with labels as enclosure
			& + label {
				color: var(--muted-color);
			}
		}
	}
}

.mixin-radio-default() {
	input[type=radio] {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: white;
		margin: 0 0.3rem 0 0.3rem;
		font: inherit;
		color: currentColor;
		width: 0.9rem;
		height: 0.9rem;
		border: var(--border-width) solid currentColor;
		border-radius: 50%;
		vertical-align: text-top;
		&:checked {
			box-shadow: inset 0 0 0 0.15rem white;
			background-color: var(--primary-color);
		}
		&:disabled {
			color: var(--muted-color);
			cursor: not-allowed;
			// this does not work with labels as enclosure
			& + label {
				color: var(--muted-color);
			}
		}
	}
}
